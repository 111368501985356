import React from "react"
import PageRoot from "../../../components/PageRoot"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"
import { Button, Divider } from "antd"
import { Link } from "gatsby"
import { translatePath } from "../../../utils"

const ErrorScalapay = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageRoot title={t("label:scalapayPaymentFailed")} isDark={false}>
        <div className="checkout-form-wrapper mt">
          <ContainerWithPadding size="small" align="center">
            <h2>{t("message:scalapayPurchaseFailed")}</h2>
            <p>{t("message:scalapayPurchaseRequestFailedDescription")}</p>
            <br />
            <br />
            <Divider>{t("message:or")}</Divider>
            <Button className="yellowsp" type="primary">
              <Link to={translatePath("/")}>{t("button:goHome")}</Link>
            </Button>
          </ContainerWithPadding>
        </div>
      </PageRoot>
    </>
  )
}

export default ErrorScalapay
